.t {
  transform: none;
}
[footer] {
  position: relative;
  background-color: #000;
  color: #fff;
  padding-top: 3.125rem;
  padding-right: 1.25rem;
  padding-bottom: 3.125rem;
  padding-left: 1.25rem;
  text-align: center;
}
[footer] .go-top {
  position: absolute;
  left: 50%;
  top: -4.25rem;
  right: auto;
  bottom: auto;
  transform: translateX(-50%);
  width: 3rem;
  height: 3rem;
  display: block;
  background-image: url('/img/common/ico-top.svg');
  transition: all 0.3s 0s;
}
.no-touch [footer] .go-top:hover:not(.hover-disable) {
  transform: translateY(-10px);
}
[footer] .contact p {
  font-size: 1.375rem;
  line-height: 1.9375rem;
  font-weight: 700;
}
[footer] .contact a {
  display: inline-block;
  padding-top: 0.875rem;
  padding-right: 4rem;
  padding-bottom: 0.875rem;
  padding-left: 4rem;
  font-size: 0.875rem;
  line-height: 0.0625rem;
  line-height: 1;
  border-radius: 0.1875rem;
  border: 1px solid #fff;
  margin-top: 1rem;
  font-weight: 500;
  transition: all 0.3s 0s;
}
.no-touch [footer] .contact a:hover:not(.hover-disable) {
  background-color: #fff;
  color: #2e2e2e;
}
[footer] .menu {
  margin-top: 3.125rem;
  text-align: left;
  font-family: 'IBM Plex Mono', monospace;
}
[footer] .menu li {
  font-size: 0.9375rem;
  line-height: 0.0625rem;
  line-height: 1;
  padding-top: 0.625rem;
  padding-right: 0rem;
  padding-bottom: 0.625rem;
  padding-left: 0rem;
  border-bottom: 1px solid #292929;
}
[footer] .menu li:first-of-type {
  border-top: 1px solid #292929;
}
.no-touch [footer] .menu li a:hover:not(.hover-disable) {
  text-decoration: underline;
}
[footer] .copyright {
  margin-top: 1.875rem;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  
}
@media (min-width: 768px) {
  [footer] {
    padding-top: 4rem;
    padding-right: 0rem;
    padding-bottom: 4rem;
    padding-left: 0rem;
  }
  [footer] .contact a {
    margin-top: 1rem;
  }
  [footer] .menu {
    margin-top: 3rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  [footer] .menu li {
    padding-top: 0rem;
    padding-right: 0.9375rem;
    padding-bottom: 0rem;
    padding-left: 0.9375rem;
    border-bottom: 0;
  }
  [footer] .menu li:first-of-type {
    border-top: 0;
  }
  [footer] .copyright {
    margin-top: 1.5rem;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    
  }
}
@media (min-width: 1024px) {
  [footer] .go-top {
    transform: translateX(0);
    right: 2rem;
    top: -5rem;
    bottom: auto;
    left: auto;
  }
}
