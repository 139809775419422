@import "base.css";
@import "proj";
@import "responsive";
@import "font";

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

html { font-size: 16px; line-height: 26px; color: #2e2e2e; }
html,body { width:100%; height:100%; }

#app { .rel; font-family:  'Pretendard' ,sans-serif; }
#app[data-server-rendered] { .hide; }

.ir { .ir; }

h2, h3, h4 { color: #1e1e1e; }

.inner-wrap { .rel; .m(0, 36); }

video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

@media (min-width: @screen-tp-min) {
  .inner-wrap { .m(0, 40); }
}

@media (min-width: @screen-tl-min) {
  .inner-wrap { .w(864); .mh-c; }
}
@media (min-width: @screen-ds-min) {
  .inner-wrap { .w(1120); .mh-c; }
}
@media (min-width: @screen-dl-min) {
  .inner-wrap { .w(1440); .mh-c; }
}
